import { initClient } from '@sunrise/client'
import { initHotReload } from './hotReload'
import { defineAsyncComponent } from 'vue'

const dynamicImports = {
    HydratedChatbot: defineAsyncComponent(
        () => import('./cmsElements/M/M_Chatbot_B2C/HydratedChatbot/HydratedChatbot.vue'),
    ),
    HydratedContactForm: defineAsyncComponent(
        () => import('./cmsElements/M/M_FAQ_Contact_Form/HydratedContactForm/HydratedContactForm.vue'),
    ),
}

initClient({
    adsEnabled: false,
    dynamicImports,
})

initHotReload()
